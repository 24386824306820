import b64toBlob from 'b64-to-blob';

export function findMasks(results) {
  let masks = [];
  if (!results) {
    return [];
  }
  for (let result of results) {
    if (result.measurement_locations) {
      for (let measurement of result.measurement_locations) {
        if (measurement.mask_shapes) {
          measurement.mask_shapes.name = measurement.description;
          masks.push(measurement.mask_shapes);
        }
      }
    }
  }
  return masks;
}

export function getImageDimensions(imageBase64) {
  return new Promise(function (resolve) {
    let imageObject = document.createElement('img');
    imageObject.onload = function () {
      resolve({
        width: imageObject.width,
        height: imageObject.height,
      });
    };
    imageObject.src = imageBase64;
  });
}

export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth) {
  let ratio = maxWidth / srcWidth;
  return { width: Math.round(srcWidth * ratio), height: Math.round(srcHeight * ratio) };
}

export function base64MimeType(encoded) {
  let result = null;
  if (typeof encoded !== 'string') {
    return result;
  }
  let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (mime && mime.length) {
    result = mime[1];
  }
  return result;
}

export function converImageToBitmap(image) {
  let contentType = base64MimeType(image);
  return b64toBlob(image.replace('data:' + contentType + ';base64,', ''), contentType);
}

export function convertFileToBase64(file) {
  return new Promise((resolve) => {
    if (file) {
      let reader = new FileReader();
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.readAsDataURL(file);
    }
  });
}
