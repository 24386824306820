export function draw(canvas, mask, imageBase64 = null, fillColor = null) {
  return new Promise((resolve, reject) => {
    if (!canvas || !mask) {
      console.error('Draw function: canvas and mask are mandatory');
      reject();
    }
    let acontext = canvas.getContext('2d');
    if (imageBase64) {
      let imgObject = document.createElement('img');
      imgObject.src = imageBase64;
      imgObject.onload = function () {
        drawShapes(acontext, mask.shapes, imgObject, fillColor);
        resolve();
      };
    } else {
      drawShapes(acontext, mask.shapes, null, fillColor);
      resolve();
    }
  });
}

function drawShapes(acontext, shapes, imgObject = null, fillColor = null) {
  for (let shape of shapes) {
    acontext.save();
    let params = shape.params.slice(0); // copy arr
    if (shape.type === 'ellipse') {
      drawEllipse(acontext, params, shape.color, imgObject, fillColor);
    }
    if (shape.type === 'polygon' || shape.type === 'unrestricted_polygon') {
      drawPolygon(acontext, params, shape.color, imgObject, fillColor);
    }
    acontext.restore();
  }
  acontext.save();
}


function drawPolygon(ctx, params, color, img, fillColor) {
  ctx.fillStyle = fillColor || 'rgb(255, 255, 255)';
  let globalCompositeOriginal = ctx.globalCompositeOperation;
  if (color === 0) {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillStyle = 'rgb(255, 255, 255)';
  }
  ctx.beginPath();

  let start = params.shift();
  ctx.moveTo((start[0]), (start[1]));

  for (let coords of params) {
    ctx.lineTo((coords[0]), (coords[1]));
  }

  ctx.lineTo((start[0]), (start[1]));
  ctx.closePath();

  if (fillColor) {
    ctx.fill();
  }

  if (img) {
    ctx.clip();
    ctx.drawImage(
      img,
      0,
      0,
    );
  }

  ctx.globalCompositeOperation = globalCompositeOriginal;
}

function drawEllipse(ctx, params, color, img, fillColor) {
  if (params.length !== 7) {
    console.error('incorrect param length for ellipse');
    console.log(params);
  }
  ctx.fillStyle = fillColor || 'rgb(255, 255, 255)';
  let globalCompositeOriginal = ctx.globalCompositeOperation;
  if (color === 0) {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillStyle = 'rgb(255, 255, 255)';
  }
  ctx.beginPath();
  ctx.ellipse(
    params[0],
    params[1],
    params[2],
    params[3],
    params[4] * Math.PI / 180,
    params[5] * Math.PI / 180,
    params[6] * Math.PI / 180,
  );
  ctx.closePath();

  if (fillColor) {
    ctx.fill();
  }

  if (img) {
    ctx.clip();
    ctx.drawImage(
      img,
      0,
      0,
    );
  }
  ctx.globalCompositeOperation = globalCompositeOriginal;
}
