import { findMasks } from '../utils/generalFunctions';
import { draw } from '../utils/draw';

export default class EffectManager {
  constructor(cvResults, width, height, ratio = null) {
    if (!cvResults) {
      console.error('Error in EffectConfigurator initialization. CVRESULTS are mandatory.');
      return Object.create(null);
    }

    this._masks = findMasks(cvResults);
    this._layers = {};
    this._width = width;
    this._height = height;
    this._ratio = ratio;
  }

  getEffectConfiguration(effectName, userMasks) {
    if (!effectName) {
      console.error('Effect name and masks are mandatory');
      return null;
    }
    let masks = userMasks;
    if (!masks) {
      masks = EffectManager.effectBundles[effectName].masks;
    }
    let masksObjects = [];
    if (masks) {
      for (let mask of masks) {
        for (let allMask of this._masks) {
          if (allMask.name === mask) {
            masksObjects.push(allMask);
            break;
          }
        }
      }
      return {
        effects: EffectManager.effectBundles[effectName].effects,
        masks: masksObjects,
      };
    } else {
      return null;
    }
  }

  setEffect(userEffect, masks, intensity, fillColor, imageBase64, skintone = 1) {
    return new Promise((resolve, reject) => {
      if (parseFloat(intensity) <= 0) {
        resolve();
      }
      let effectConfiguration = this.getEffectConfiguration(userEffect, masks);
      if (!effectConfiguration) {
        reject();
      }
      let masksPending = 0;
      let firstMask = true;
      for (let mask of effectConfiguration.masks) {
        let canvas = createNewCanvas(userEffect, this._width, this._height);
        for (let effect of effectConfiguration.effects) {
          masksPending++;
          draw(canvas, mask, effect.needsSkinTexture ? imageBase64 : null,
            (effect.needsSkintoneColor ? EffectManager.skintoneColors['S' + skintone] : fillColor))
            .then(() => {
              applyEffectsInCanvas(effect, intensity, canvas, this._ratio);
              if (!this._layers[userEffect]) {
                this._layers[userEffect] = {};
              }
              if (firstMask) {
                this._layers[userEffect] = canvas;
                firstMask = false;
              } else {
                let originalCanvas = this._layers[userEffect];
                let originalCanvasContext = originalCanvas.getContext('2d');
                originalCanvasContext.drawImage(canvas, 0, 0);
                canvas.remove();
              }
              masksPending--;
              if (masksPending === 0) {
                resolve();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    });
  }
}

function createNewCanvas(id, width, height) {
  let canvas = document.createElement('canvas');
  canvas.setAttribute('id', id);
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  canvas.style.position = 'absolute';
  canvas.style.top = '0px';
  canvas.style.left = '0px';
  canvas.style.transformOrigin = 'top left';
  let acontext = canvas.getContext('2d');
  acontext.clearRect(0, 0, width, height);
  return canvas;
}

function applyEffectsInCanvas(effects, intensity, canvas, ratio) {
  if (!effects) {
    return;
  }
  let opacityApplied = false;
  for (let effect in effects) {
    if (effect === 'opacity') {
      let finalOpacity = parseFloat(intensity) * parseFloat(effects[effect]);
      canvas.style.opacity = finalOpacity;
      opacityApplied = true;
      canvas.style.opacity = finalOpacity;
    } else {
      canvas.style[effect] = effects[effect];
    }
  }
  if (ratio) {
    canvas.style.transform = 'scale(' + ratio + ')';
  }
  if (!opacityApplied && intensity) {
    canvas.style.opacity = parseFloat(intensity);
  }
}

EffectManager.skintoneColors = {
  S1: 'rgb(243, 208, 179)',
  S2: 'rgb(230, 180, 146)',
  S3: 'rgb(209, 158, 127)',
  S4: 'rgb(185, 119, 84)',
  S5: 'rgb(164, 93, 49)',
  S6: 'rgb(59, 32, 30)',
};

EffectManager.masks = {
  SKIN: 'Skin',
  FOREHEAD: 'forehead',
  FOREHEAD_WORRY_LINES: 'forehead worry lines',
  BETWEEN_BROWS_FROWN_LINES: 'between-brows frown lines',
  NOSELINE_BUNNY_LINES: 'noseline bunny lines',
  LEFT_SIDE_EYE_CROWS_FEET: 'left side eye crow\'s feet',
  RIGHT_SIDE_EYE_CROWS_FEET: 'right side eye crow\'s feet',
  LEFT_SIDE_TEARLINE: 'left side tearline',
  RIGHT_SIDE_TEARLINE: 'right side tearline',
  LEFT_EYEBAG: 'left eye',
  RIGHT_EYEBAG: 'right eye',
  EYEBROWS: 'Eyebrows',
  LEFT_CHEEK: 'left cheek',
  RIGHT_CHEEK: 'right cheek',
  LIPS: 'Lips',
};

EffectManager.effectActions = {
  reduceRedness: { id: 'reduceRedness', filter: 'blur(15px) saturate(85%) brightness(110%)', mixBlendMode: 'lighten', opacity: 0.15, needsSkintoneColor: true, needsSkinTexture: false },
  reduceWrinkles: { id: 'reduceWrinkles', filter: 'blur(30px)', opacity: 0.65, needsSkinTexture: true },
  brightenSkin: { id: 'brightenSkin', filter: 'blur(15px) saturate(85%) brightness(110%)', mixBlendMode: 'lighten', opacity: 0.15, needsSkintoneColor: true, needsSkinTexture: false },
  reduceEyebags: { id: 'reduceEyebags', filter: 'blur(5px) brightness(105%)', opacity: 0.75, needsSkinTexture: true },
  reduceDarkcircles: { id: 'reduceDarkcircles', filter: 'blur(15px) saturate(85%) brightness(125%)', mixBlendMode: 'lighten', opacity: 0.150, needsSkintoneColor: true, needsSkinTexture: false },
  colorizeHard: { id: 'colorizeHard', filter: 'blur(3px)', opacity: 0.75, needsSkinTexture: false },
  colorizeSoft: { id: 'colorizeSoft', filter: 'blur(40px)', opacity: 0.35, needsSkinTexture: false },
  foundation: { id: 'foundation', filter: 'blur(4px) brightness(110%)', opacity: 0.45, needsSkinTexture: true },
};
EffectManager.effectBundles = {
  reduceEyebags: {
    reactParameterType: 'number',
    masks: [EffectManager.masks.LEFT_EYEBAG, EffectManager.masks.RIGHT_EYEBAG],
    effects: [EffectManager.effectActions.reduceEyebags],
  },
  reduceCrowsFeet: {
    reactParameterType: 'number',
    masks: [EffectManager.masks.LEFT_SIDE_EYE_CROWS_FEET,
      EffectManager.masks.RIGHT_SIDE_EYE_CROWS_FEET],
    effects: [EffectManager.effectActions.reduceWrinkles],
  },
  reduceDarkcircles: {
    reactParameterType: 'number',
    masks: [EffectManager.masks.LEFT_EYEBAG, EffectManager.masks.RIGHT_EYEBAG],
    effects: [EffectManager.effectActions.reduceDarkcircles],
  },
  reduceRedness: {
    reactParameterType: 'object',
    effects: [EffectManager.effectActions.reduceRedness],
  },
  reduceWrinkles: {
    reactParameterType: 'object',
    effects: [EffectManager.effectActions.reduceWrinkles],
  },
  brightenSkin: {
    reactParameterType: 'object',
    effects: [EffectManager.effectActions.brightenSkin],
  },
  applyLipstick: {
    reactParameterType: 'object',
    masks: [EffectManager.masks.LIPS],
    effects: [EffectManager.effectActions.colorizeHard],
  },
  applyBlush: {
    reactParameterType: 'object',
    masks: [EffectManager.masks.LEFT_CHEEK, EffectManager.masks.RIGHT_CHEEK],
    effects: [EffectManager.effectActions.colorizeSoft],
  },
  applyFoundation: {
    reactParameterType: 'number',
    masks: [EffectManager.masks.SKIN],
    effects: [EffectManager.effectActions.foundation],
  },
};
