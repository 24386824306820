import React from 'react';
import { PropTypes } from 'prop-types';
import { RevieveAR } from '../RevieveAR';
import EffectManager from '../services/EffectManager';

export default class RevieveARComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: {
        partnerID: props.partnerID,
        skintone: props.skintone,
      },
      image: props.image,
    };
    for (const effect in EffectManager.effectBundles) {
      if (EffectManager.effectBundles[effect]) {
        this.state[effect] = props[effect];
      }
    }
  }

  componentDidMount() {
    this.initialActions();
  }

  componentWillReceiveProps(newProps) {
    const state = this.state;

    if ((newProps.partnerID && state.conf.partnerID !== newProps.partnerID)
    || (newProps.skintone && state.conf.skintone !== newProps.skintone)) {
      let newConf = {
        partnerID: (newProps.partnerID ? newProps.partnerID : state.conf.partnerID),
        skintone: (newProps.skintone ? newProps.skintone : state.conf.skintone),
      };
      this.setState({
        conf: newConf,
      }, () => {
        this.initialActions();
      });
    }
    if (newProps.image && state.image !== newProps.image) {
      this.setState({
        image: state.image,
      }, () => {
        this.initialActions();
      });
    }
    for (const effect in EffectManager.effectBundles) {
      if (EffectManager.effectBundles[effect]) {
        let effectConfiguration = EffectManager.effectBundles[effect];
        switch (effectConfiguration.reactParameterType) {
          case 'number': {
            if (newProps[effect] && newProps[effect] >= 0
              && (!state[effect] || (state[effect] && state[effect] !== newProps[effect]))) {
              let newState = {};
              newState[effect] = newProps[effect];
              this.setState(newState, this.applyFilters);
            }
            break;
          }
          case 'object': {
            if (newProps[effect] && newProps[effect] !== null
              && (!state[effect]
                || (state[effect]
                  && JSON.stringify(state[effect]) !== JSON.stringify(newProps[effect])))) {
              let newState = {};
              newState[effect] = newProps[effect];
              this.setState(newState, this.applyFilters);
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  initialActions() {
    this.instantiateSDK();
    this.initializeSDK();
  }

  instantiateSDK() {
    const { conf, image } = this.state;
    this.revieveAR = new RevieveAR(conf, image, 'revieveARContainer');
  }

  initializeSDK() {
    this.revieveAR.initialize().then(() => {
      this.setState({
        image: this.revieveAR.getImageBefore(),
      });
      this.applyFilters();
    }).catch((error) => {
      console.error(error);
    });
  }

  applyFilters() {
    const state = this.state;
    for (const effect in EffectManager.effectBundles) {
      if (EffectManager.effectBundles[effect]) {
        let effectConfiguration = EffectManager.effectBundles[effect];
        switch (effectConfiguration.reactParameterType) {
          case 'number': {
            if (state[effect] && state[effect] >= 0) {
              this.revieveAR[effect].call(this.revieveAR, state[effect]);
            }
            break;
          }
          case 'object': {
            if (state[effect] && state[effect] !== null) {
              if (state[effect].areas) {
                this.revieveAR[effect].call(this.revieveAR,
                  state[effect].value, state[effect].areas);
              }
              if (state[effect].color) {
                this.revieveAR[effect].call(this.revieveAR,
                  state[effect].value, state[effect].color);
              }
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: 'auto' }} id="revieveARContainer" />
    );
  }
}

// we build the propTypes and defaultProps object depending of the effects defined in the SDK
let propTypes = {
  image: PropTypes.string.isRequired,
  partnerID: PropTypes.string.isRequired,
  skintone: PropTypes.number,
};

let defaultProps = {
  skintone: 1,
};
for (const effect in EffectManager.effectBundles) {
  if (EffectManager.effectBundles[effect]) {
    let effectConfiguration = EffectManager.effectBundles[effect];
    switch (effectConfiguration.reactParameterType) {
      case 'number': {
        propTypes[effect] = PropTypes.number;
        defaultProps[effect] = -1;
        break;
      }
      case 'object': {
        propTypes[effect] = PropTypes.object;
        defaultProps[effect] = null;
        break;
      }
      default: {
        break;
      }
    }
  }
}

RevieveARComponent.propTypes = propTypes;
RevieveARComponent.defaultProps = defaultProps;
