import { serverUrl, apiVersion } from '../appConf';
import { converImageToBitmap } from '../utils/generalFunctions';
// import { analyzeImageResponse as response } from '../test/responses/analyzeImage';
import 'whatwg-fetch';


export default function analyzeImage(image, partnerId, skintone) {
  let url = serverUrl + 'api/analyzeImage/' + apiVersion + '/';
  if (skintone) {
    url += '?skintone=' + skintone;
  }

  let data = new FormData();
  data.append('image', converImageToBitmap(image));
  data.append('partner_id', partnerId);

  // return new Promise(function (resolve) {
  //   resolve(response);
  // });

  return fetch(url, {
    method: 'POST',
    body: data,
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch((error) => {
      console.log('Request failed', error);
      return { message: 'Error' };
    });
}

function checkStatus(requestResponse) {
  if (requestResponse.status >= 200 && requestResponse.status < 300) {
    return requestResponse;
  } else {
    let error = new Error(requestResponse.statusText);
    error.requestResponse = requestResponse;
    throw error;
  }
}
